import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"

const Step6 = () => (
  <StaticQuery
      query={graphql`
        query {
          allWpPage(filter: {slug: {eq: "calculator"}}) {
            edges {
              node {
                TeamlanceCalculator {
                  calculatorSteps {
                    emailPlaceholder
                    fieldGroupName
                    namePlaceholder
                    phonePlaceholder
                    privacyText
                    steps {
                      buttonText
                      description
                      fieldGroupName
                      heading
                      iconSvg
                      icon {
                        sourceUrl
                      }
                      options {
                        optionTitle
                      }
                      percentComplete
                      title
                    }
                  }
                  designSettings {
                    buttonBg
                    leftMenuBg
                    leftTextColor
                    logo {
                      sourceUrl
                    }
                    logoSvg
                    rightTextColor
                  }
                }
                slug
              }
            }
          }
        }
      `}
      render={data => {
        return(
          data && data.allWpPage &&
          data.allWpPage.edges &&
          data.allWpPage.edges[0] &&
          data.allWpPage.edges[0].node &&
          data.allWpPage.edges[0].node.TeamlanceCalculator &&
          data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps &&
          data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps&&
          data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps.length>0&&
          <div className="mainContentWrapper">
          <div
            className="back d-flex align-items-center"
          >
            <svg
              width="7"
              height="10"
              viewBox="0 0 7 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 8.75L1.75 5L5.5 1.25"
                stroke="#C4C4C4"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p className="mb-0 ml-3">Back</p>
          </div>
          <div className="row dashboardCardRow ">
            <div className="col-md-10 col-lg-8 d-flex align-items-center ">
              <div className="dashboard-content w-100">
                <figure dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceCalculator.calculatorSteps.steps[5].iconSvg}} className="my-5"></figure>
                <h1>Total Estimation</h1>
                <div className="estimate">
                  <div className="d-flex pricing justify-content-between">
                    <p className="mb-0">Project Timing</p>
                    <p className="bold mb-0">$1400</p>
                  </div>
                  <hr />
                  <div className="d-flex pricing justify-content-between">
                    <p className="mb-0">Webshop</p>
                    <p className="bold mb-0">$6400</p>
                  </div>
                  <hr />
                  <div className="d-flex pricing justify-content-between">
                    <p className="mb-0">Design Quality</p>
                    <p className="bold mb-0">$4500</p>
                  </div>
                  <hr />
                  <div className="d-flex pricing justify-content-between">
                    <p className="mb-0">Website Content</p>
                    <p className="bold mb-0">$5500</p>
                  </div>
                  <hr />
                  <div class="d-flex pricing justify-content-between">
                    <p class="mb-0">Website Integration</p>
                    <p class="bold mb-0">$12500</p>
                  </div>
                  <hr />
      
                  <div class="d-flex total-prz justify-content-between">
                    <p class="mb-0">Total</p>
                    <p class="bold mb-0">$30,300</p>
                  </div>
                </div>
                <div class="text-left mt-4">
                  <Link to="/calculator/calculator-step-7">
                    <button
                      // onclick="location.href = 'calculator-step-7.html'; return false;"
                      data-abc="true"
                      class="btn btn-default waves-effect waves-light next-step"
                    >
                      Next Step
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      }}
      />
  
)

export default Step6
