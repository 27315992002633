import React from 'react';
import Layout from "../../components/calculator/layout";
import SEO from "../../components/seo";

import Sidebar from "../../components/calculator/sidebar";
import Step6 from "../../components/calculator/step-6";

const CalculatorStep6 = (props) => (
    <Layout>
        <SEO title="Calculator Step 6"/>
        <div id="wrapper" class="d-flex justify-content-between">
                  <Sidebar {...props}/> 
            <Step6/>
        </div>
       
    </Layout>
)

export default CalculatorStep6;

